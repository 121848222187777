"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ODataRemoteRepo = void 0;
var ts_events_1 = require("ts-events");
var StdApplicationError_1 = require("../../entities/StdApplicationError");
var ODataRemoteRepo = function () {
  function ODataRemoteRepo(exampleEntity, remoteDB) {
    (0, _classCallCheck2.default)(this, ODataRemoteRepo);
    this.changeEvent = new ts_events_1.AsyncEvent();
    this._exampleEntity = exampleEntity;
    this._remoteDB = remoteDB;
  }
  (0, _createClass2.default)(ODataRemoteRepo, [{
    key: "action",
    value: function () {
      var _action = (0, _asyncToGenerator2.default)(function* (command, data) {
        var url = `/${this._exampleEntity.entityName.toLowerCase()}/${command}`;
        return this._remoteDB.fetchData(url, {
          method: 'POST',
          body: data
        });
      });
      function action(_x, _x2) {
        return _action.apply(this, arguments);
      }
      return action;
    }()
  }, {
    key: "count",
    value: function () {
      var _count = (0, _asyncToGenerator2.default)(function* (filter) {
        var url = `/${this._exampleEntity.entityName.toLowerCase()}/$count`;
        if (filter != null && filter.length > 0) {
          url += `?$filter=${escape(filter)}`;
        }
        return this._remoteDB.fetchData(url);
      });
      function count(_x3) {
        return _count.apply(this, arguments);
      }
      return count;
    }()
  }, {
    key: "create",
    value: function create(c) {
      return new c();
    }
  }, {
    key: "delete",
    value: function () {
      var _delete2 = (0, _asyncToGenerator2.default)(function* (entityId) {
        var url = `/${this._exampleEntity.entityName.toLowerCase()}/(${entityId})`;
        return this._remoteDB.fetchData(url, {
          method: 'DELETE'
        });
      });
      function _delete(_x4) {
        return _delete2.apply(this, arguments);
      }
      return _delete;
    }()
  }, {
    key: "deleteFilter",
    value: function () {
      var _deleteFilter = (0, _asyncToGenerator2.default)(function* (filter) {
        var url = filter != null ? `/${this._exampleEntity.entityName.toLowerCase()}/$filter=${filter}` : `/${this._exampleEntity.entityName.toLowerCase()}/`;
        return this._remoteDB.fetchData(url, {
          method: 'DELETE'
        });
      });
      function deleteFilter(_x5) {
        return _deleteFilter.apply(this, arguments);
      }
      return deleteFilter;
    }()
  }, {
    key: "distinct",
    value: function () {
      var _distinct = (0, _asyncToGenerator2.default)(function* (select, filter) {
        var url = `/${this._exampleEntity.entityName.toLowerCase()}/$distinct?$select=${select.toString()}`;
        if (filter != null && filter.length > 0) {
          url += `&$filter=${escape(filter)}`;
        }
        return this._remoteDB.fetchData(url);
      });
      function distinct(_x6, _x7) {
        return _distinct.apply(this, arguments);
      }
      return distinct;
    }()
  }, {
    key: "get",
    value: function () {
      var _get = (0, _asyncToGenerator2.default)(function* (options) {
        var url = `/${this._exampleEntity.entityName.toLowerCase()}`;
        var query = '';
        if (options != null) {
          if (options.select != null) {
            query += '&$select=';
            query += typeof options.select === 'string' ? options.select : options.select.toString();
          }
          if (options.orderby != null) {
            query += `&$orderby=${options.orderby.toString()}`;
          }
          if (options.top != null) {
            query += `&$top=${options.top.toString()}`;
          }
          if (options.skip != null) {
            query += `&$skip=${options.skip.toString()}`;
          }
          if (options.filter != null && options.filter.length > 0) {
            query += `&$filter=${escape(options.filter)}`;
          }
        }
        if (query != null && query.length > 0) {
          url = `${url}?${query.substr(1)}`;
        }
        var result = yield this._remoteDB.fetchData(url);
        if (result == null) {
          return [];
        }
        var typedResult = [];
        for (var e of result) {
          typedResult.push(this._exampleEntity.createOne(e));
        }
        return typedResult;
      });
      function get(_x8) {
        return _get.apply(this, arguments);
      }
      return get;
    }()
  }, {
    key: "getById",
    value: function () {
      var _getById = (0, _asyncToGenerator2.default)(function* (id, select) {
        var url = `/${this._exampleEntity.entityName.toLowerCase()}/(${id})`;
        if (select != null) {
          url += '?$select=';
          url += typeof select === 'string' ? select : select.toString();
        }
        var result = yield this._remoteDB.fetchData(url);
        if (result != null) {
          return this._exampleEntity.createOne(result);
        }
        throw StdApplicationError_1.StdApplicationError.notFound(`${this._exampleEntity.entityName}notFound`, `${this._exampleEntity.entityName}notFound`, {
          id: id
        });
      });
      function getById(_x9, _x10) {
        return _getById.apply(this, arguments);
      }
      return getById;
    }()
  }, {
    key: "patch",
    value: function () {
      var _patch = (0, _asyncToGenerator2.default)(function* (entity) {
        var url = `/${this._exampleEntity.entityName.toLowerCase()}`;
        var result = yield this._remoteDB.fetchData(url, {
          method: 'PATCH',
          body: entity
        });
        if (result != null) {
          return this._exampleEntity.createOne(result);
        }
        throw StdApplicationError_1.StdApplicationError.notFound(`${this._exampleEntity.entityName}notFound`);
      });
      function patch(_x11) {
        return _patch.apply(this, arguments);
      }
      return patch;
    }()
  }, {
    key: "post",
    value: function () {
      var _post = (0, _asyncToGenerator2.default)(function* (entity) {
        var url = `/${this._exampleEntity.entityName.toLowerCase()}`;
        var result = yield this._remoteDB.fetchData(url, {
          method: 'POST',
          body: entity
        });
        if (result != null) {
          return this._exampleEntity.createOne(result);
        }
        throw StdApplicationError_1.StdApplicationError.notFound(`${this._exampleEntity.entityName}notFound`);
      });
      function post(_x12) {
        return _post.apply(this, arguments);
      }
      return post;
    }()
  }, {
    key: "postOrPatchMany",
    value: function () {
      var _postOrPatchMany = (0, _asyncToGenerator2.default)(function* (entities) {
        var url = `/${this._exampleEntity.entityName.toLowerCase()}`;
        var result = yield this._remoteDB.fetchData(url, {
          method: 'POST',
          body: entities
        });
        if (result != null) {
          return result;
        }
        throw StdApplicationError_1.StdApplicationError.notFound(`${this._exampleEntity.entityName}notFound`);
      });
      function postOrPatchMany(_x13) {
        return _postOrPatchMany.apply(this, arguments);
      }
      return postOrPatchMany;
    }()
  }, {
    key: "patchManyWithFilter",
    value: function () {
      var _patchManyWithFilter = (0, _asyncToGenerator2.default)(function* (body, filter) {});
      function patchManyWithFilter(_x14, _x15) {
        return _patchManyWithFilter.apply(this, arguments);
      }
      return patchManyWithFilter;
    }()
  }]);
  return ODataRemoteRepo;
}();
exports.ODataRemoteRepo = ODataRemoteRepo;